import React from 'react';
import {
  Typography, Box, Button,
} from '@mui/material';
import Mail from '@mui/icons-material/Mail';
import { colors } from './Theme';

function Cancelled() {
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      paddingBottom: '40px',
    }}
    >
      <Box sx={{
        width: '100%', height: '100px', backgroundColor: colors.lightGrey, clipPath: 'polygon(100% 0, 0 0%, 100% 100%)',
      }}
      />
      <Typography
        variant="h1"
        sx={{
          paddingTop: '40px',
          paddingBottom: '40px',
          fontStyle: 'italic',
        }}
      >
        Credits Purchase Cancelled :(
      </Typography>
      <Typography variant="h5" width="50%" sx={{ fontWeight: '500', textAlign: 'center', fontSize: '1rem' }}>
        Need to rethink your decision?
      </Typography>
      <Button
        variant="contained"
        color="primary"
        type="button"
        startIcon={<Mail />}
        onClick={() => { window.location.href = 'mailto:karl@solcraftcity.com'; }}
        sx={{
          whiteSpace: 'nowrap',
          borderRadius: '25px',
          textTransform: 'none',
          padding: '16px',
          marginTop: '40px',
          backgroundColor: '#000',
          color: '#fff',
        }}
      >
        Let us know how we can help.
      </Button>
    </Box>
  );
}

export default Cancelled;
