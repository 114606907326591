import React from 'react';
import {
  Box, Typography, Button, Grid, useTheme, useMediaQuery,
} from '@mui/material';
import ArrowCircleUp from '@mui/icons-material/ArrowCircleUp';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { colors } from './Theme';
import mainOne from './assets/images/main_1.png';
import exampleOne from './assets/images/example_1.JPG';
import exampleTwo from './assets/images/example_2.jpg';

export default function Main() {
  const {
    isAuthenticated,
    loginWithRedirect,
  } = useAuth0();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const fontSize = isSmallScreen ? '2rem' : '3.125rem';
  const fontSizeBig = isSmallScreen ? '3rem' : '6.125rem';

  const navigate = useNavigate();

  return (
    <Box sx={{ width: '100%' }}>
      <Grid container spacing={2} sx={{ padding: '5%', backgroundColor: colors.lightGrey }}>
        <Grid item xs={12} sm={6}>
          <Box sx={{ padding: '' }}>
            <Typography variant="h6">
              Fast & effortless!
            </Typography>
            <Typography variant="h1" sx={{ paddingBottom: '40px' }}>
              Generate your Social Media Captions by AI.
            </Typography>
            <Typography variant="h5" sx={{ paddingBottom: '40px' }}>
              Save time and effort by uploading and a photo and letting AI generate the caption!
            </Typography>
            <Button
              variant="contained"
              type="button"
              onClick={() => (isAuthenticated ? navigate('/generate') : loginWithRedirect())}
              endIcon={<ArrowCircleUp />}
              sx={{
                color: '#fff',
                whiteSpace: 'nowrap',
                borderRadius: '25px',
                textTransform: 'none',
                paddingTop: '16px',
                paddingBottom: '16px',
                paddingLeft: '40px',
                paddingRight: '40px',
                backgroundColor: '#000',
              }}
            >
              Try it now!
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box sx={{
            height: '600px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden',
          }}
          >
            <img src={mainOne} alt="Logo" style={{ height: '100%', width: '100%', objectFit: 'contain' }} />
          </Box>
        </Grid>
      </Grid>
      <Box sx={{
        width: '100%', height: '100px', backgroundColor: colors.lightGrey, clipPath: 'polygon(100% 0, 0 0%, 100% 100%)',
      }}
      />
      <Grid container justifyContent="center" alignItems="center" direction="column" sx={{ padding: '5%' }}>
        <Grid item xs={12}>
          <Typography
            variant="h1"
            sx={{
              marginBottom: '-30px', color: '#00000008', fontSizeBig, textAlign: 'center',
            }}
          >
            Generate captions
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h3" sx={{ fontSize }}>
            Examples generated by Capgen City
          </Typography>
        </Grid>
      </Grid>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '40px' }}>
        <Grid container spacing={2} justifyContent="center" alignItems="top">
          <Grid item xs={12} sm={6}>
            <Box
              key={1}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '20px',
              }}
            >
              <Typography variant="h4" sx={{ textAlign: 'center', maxWidth: '75%' }}>
                Caption based on a photo of a corgi with positive tonality.
              </Typography>
              <img
                src={exampleOne}
                alt={`Example ${1}`}
                style={{
                  marginTop: '20px',
                  maxWidth: '75%',
                  maxHeight: '900px',
                  height: 'auto',
                  marginBottom: '16px',
                  border: `5px solid ${colors.lightGrey}`, // Add a border
                  borderRadius: '20px', // Add rounded corners
                  objectFit: 'cover', // Prevent changing aspect ratio
                }}
              />
              <Typography
                variant="body1"
                style={{
                  border: `5px solid ${colors.lightGrey}`,
                  backgroundColor: ` ${colors.lightGrey}`,
                  padding: '10px',
                  maxWidth: '75%',
                  borderRadius: '20px',
                }}
                sx={{ textAlign: 'justify' }}
              >
                Stepping into the week like a boss! 😄🐾
                This little cutie is all dressed up and ready for endless fun and adventure. 🌟
                Remember, a good outfit can set the tone for a great day!
                #CorgiCutie #MondayMotivation #DogsofInstagram #FashionFurriend 😊👑
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              key={2}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '20px',
              }}
            >

              <Typography variant="h4" sx={{ textAlign: 'center', maxWidth: '75%' }}>
                Caption based on a photo of a master at work with neutral tonality.
              </Typography>
              <img
                src={exampleTwo}
                alt={`Example ${1}`}
                style={{
                  marginTop: '20px',
                  maxWidth: '75%',
                  maxHeight: '900px',
                  height: 'auto',
                  marginBottom: '16px',
                  border: `5px solid ${colors.lightGrey}`,
                  borderRadius: '20px',
                  objectFit: 'cover',
                }}
              />
              <Typography
                variant="body1"
                style={{
                  maxWidth: '75%',
                  maxHeight: '900px',
                  border: `5px solid ${colors.lightGrey}`,
                  backgroundColor: ` ${colors.lightGrey}`,
                  padding: '10px',
                  borderRadius: '20px',
                }}
                sx={{ textAlign: 'justify' }}
              >
                Nestled in the bustling streets of Hong Kong, a moment of tradition unfolds. 🏺
                Here, the age-old craft of pottery mingles with the modern pulse of the city.
                A master at work, shaping beauty from the earth, reminding us of the
                enduring spirit of craftsmanship.
                ✨ Whether its a quest for the unique or a nod to the artisans, every piece tells a
                story. #TraditionalCrafts
                #HongKongPottery #ArtisanalCharm #UrbanOasis #HandmadeHeritage #CulturalThreads 🎨🤲
              </Typography>
            </Box>
          </Grid>
        </Grid>

      </Box>
      <Box sx={{ width: '100%', alignItems: 'center' }}>
        <Typography variant="h6" sx={{ textAlign: 'center', marginTop: '40px', color: colors.textColor }}>
          Save time and effort and get inspired by AI Captions!
        </Typography>
      </Box>
      <Box sx={{
        width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '40px',
      }}
      >
        <Button
          variant="contained"
          type="button"
          onClick={() => (isAuthenticated ? navigate('/generate') : loginWithRedirect())}
          endIcon={<ArrowCircleUp />}
          sx={{
            color: '#fff',
            whiteSpace: 'nowrap',
            borderRadius: '25px',
            textTransform: 'none',
            paddingTop: '16px',
            paddingBottom: '16px',
            paddingLeft: '40px',
            paddingRight: '40px',
            backgroundColor: '#000',
          }}
        >
          Try it now!
        </Button>
      </Box>
    </Box>

  );
}
