import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Box, Button, Typography, Avatar, IconButton,
} from '@mui/material';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import ShareIcon from '@mui/icons-material/Share';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import { useLocation } from 'react-router-dom';

import { TypeAnimation } from 'react-type-animation';
import { colors } from './Theme';

function GenerateSuccess() {
  const {
    user, isAuthenticated,
  } = useAuth0();
  const username = user.nickname ?? user.email;
  const likes = Math.floor(Math.random() * (100000 - 1000 + 1)) + 1000;
  const location = useLocation();
  const { image, caption } = location.state;

  return (
    isAuthenticated && (
      <Box>
        <Box sx={{ width: '100%' }}>
          <Box sx={{
            width: '100%', height: '100px', backgroundColor: colors.lightGrey, clipPath: 'polygon(100% 0, 0 0%, 100% 100%)',
          }}
          />
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '80%',
            paddingX: '10%',
            paddingTop: '20px',
          }}
          >
            <Typography variant="h3" sx={{ marginBottom: '20px' }}>
              👇 Your caption is ready! 👇
            </Typography>
            <Box sx={{
              width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center',
            }}
            >
              <TypeAnimation
                sequence={[
                  caption.response,
                ]}
                wrapper="span"
                speed={50}
                style={{ fontSize: '2em', alignContent: 'center', textAlign: 'center' }}
              />
            </Box>
            <Button
              onClick={async () => navigator.clipboard.writeText(caption.response)}
              color="primary"
              variant="contained"
              sx={{
                color: '#fff',
                mr: 4,
                textTransform: 'none',
                borderRadius: '25px',
                padding: '10px 20px',
                marginTop: '20px',
                marginBottom: '20px',
                backgroundColor: colors.primary,
              }}
            >
              Copy my caption!
            </Button>
            <Box sx={{ marginBottom: '20px' }} />
            <Typography variant="h4" sx={{ marginBottom: '20px', color: '#000' }}>
              Your social media post could look like this! 🎉🎉🎉
            </Typography>
            <Box sx={{ marginBottom: '20px' }} />
            <Box sx={{ maxWidth: '600px', width: '100%', border: `2px solid ${colors.lightGrey}` }}>
              <Box sx={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
                <Avatar>{username.charAt(0).toUpperCase()}</Avatar>
                <Box sx={{ marginLeft: '10px' }}>
                  <Typography variant="subtitle1"><strong>{username}</strong></Typography>
                </Box>
              </Box>
              <img src={image} alt="Post" style={{ width: '100%', height: 'auto' }} />
              <Box sx={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
                <IconButton><FavoriteBorderIcon /></IconButton>
                <IconButton><ChatBubbleOutlineIcon /></IconButton>
                <IconButton><ShareIcon /></IconButton>
                <Box sx={{ flexGrow: 1 }} />
                <IconButton><BookmarkBorderIcon /></IconButton>
              </Box>
              <Box sx={{ paddingLeft: '10px' }}>
                <Typography variant="body2">
                  <strong>
                    {` ${likes}  likes`}
                  </strong>
                </Typography>
              </Box>
              <Box sx={{ paddingLeft: '10px', paddingBottom: '10px', paddingRight: '10px' }}>
                <Typography variant="body2">
                  <strong>{username}</strong>
                  {` ${caption.response}`}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ marginBottom: '20px' }} />
            <Typography variant="h4" sx={{ marginBottom: '20px', color: '#000' }}>
              Thank you for generating with Capgen City! 🙏🙏🙏
            </Typography>

          </Box>
        </Box>
      </Box>
    )
  );
}

export default GenerateSuccess;
