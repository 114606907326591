/* eslint-disable no-nested-ternary */
import React from 'react';
import { Typography, Box } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { colors } from './Theme';

export default function Footer() {
  const {
    isAuthenticated, isLoading,
  } = useAuth0();

  return (
    <Box>
      <Box sx={{
        width: '100%', height: '100px', backgroundColor: colors.lightGrey, clipPath: 'polygon(0 100%, 0 0%, 100% 100%)',
      }}
      />
      <Box sx={{ paddingLeft: '24px', paddingRight: '24px', backgroundColor: colors.lightGrey }}>
        <Box sx={{
          display: 'flex',
          paddingTop: '40px',
          justifyContent: 'space-between',
          width: '90%',
          backgroundColor: colors.lightGrey,
          paddingLeft: '5%',
          paddingRight: '5%',
          paddingBottom: '40px',
        }}
        >
          <Box sx={{ flex: 1 }}>
            { isLoading ? (<div />) : isAuthenticated
              ? (<LoggedInPages />)
              : (<LoggedOutPages />) }
          </Box>
          <Box sx={{
            flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center',
          }}
          >
            <Typography variant="h6" sx={{ fontStyle: 'normal', textAlign: 'center' }}>
              Copyright © 2024 Solcraft City. All rights reserved.
            </Typography>
          </Box>
          <Box sx={{
            display: 'flex', flexDirection: 'column', flex: 1, textAlign: 'right',
          }}
          >
            <Typography
              variant="h6"
              component={Link}
              target="_blank"
              to="/tnc.html"
              onClick={() => {
                ReactGA.event('page_view', { page_location: '/tnc', is_authenticated: isAuthenticated });
              }}
              sx={{
                color: colors.darkBlue, fontStyle: 'normal', textDecoration: 'none', fontSize: '1rem',
              }}
            >
              Terms and Conditions
            </Typography>
            <Typography
              variant="h6"
              component={Link}
              target="_blank"
              to="/privacy.html"
              onClick={() => {
                ReactGA.event('page_view', { page_location: '/privacy', is_authenticated: isAuthenticated });
              }}
              sx={{
                color: colors.darkBlue, fontStyle: 'normal', textDecoration: 'none', fontSize: '1rem',
              }}
            >
              Privacy Policy
            </Typography>
            <Typography
              variant="h6"
              component={Link}
              to="/support"
              sx={{
                color: colors.darkBlue, fontStyle: 'normal', textDecoration: 'none', fontSize: '1rem',
              }}
            >
              Support
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

function LoggedInPages() {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h6" sx={{ color: colors.darkBlue, fontStyle: 'normal', fontWeight: 900 }}>
        Pages
      </Typography>
      <Typography
        variant="h6"
        component={Link}
        to="/"
        sx={{
          textDecoration: 'none', color: colors.darkBlue, fontStyle: 'normal', fontSize: '1rem',
        }}
      >
        Home
      </Typography>
      <Typography
        variant="h6"
        component={Link}
        to="/generate"
        sx={{
          textDecoration: 'none', color: colors.darkBlue, fontStyle: 'normal', fontSize: '1rem',
        }}
      >
        Generate!
      </Typography>
      <Typography
        variant="h6"
        component={Link}
        to="/transactions"
        sx={{
          textDecoration: 'none', color: colors.darkBlue, fontStyle: 'normal', fontSize: '1rem',
        }}
      >
        Transactions
      </Typography>
      <Typography
        variant="h6"
        component={Link}
        to="/purchase"
        sx={{
          textDecoration: 'none', color: colors.darkBlue, fontStyle: 'normal', fontSize: '1rem',
        }}
      >
        Purchase Credits
      </Typography>
    </Box>
  );
}

function LoggedOutPages() {
  return (
    <Box sx={{ flexDirection: 'column' }}>
      <Typography variant="h6" sx={{ color: colors.darkBlue, fontStyle: 'normal', fontWeight: 900 }}>
        Pages
      </Typography>
      <Typography
        variant="h6"
        component={Link}
        to="/pricing"
        sx={{
          color: colors.darkBlue, fontStyle: 'normal', textDecoration: 'none', fontSize: '1rem',
        }}
      >
        Pricing
      </Typography>
    </Box>
  );
}
