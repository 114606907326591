import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@mui/material';
import Person from '@mui/icons-material/Person';

function LoginButton(key) {
  const { loginWithRedirect } = useAuth0();
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Button
        key={key}
        variant="contained"
        color="secondary"
        type="button"
        onClick={() => loginWithRedirect()}
        startIcon={<Person />}
        sx={{
          color: '#fff', whiteSpace: 'nowrap', borderRadius: '25px', textTransform: 'none', marginX: '8px',
        }}
      >
        Log in
      </Button>
    </div>
  );
}

export default LoginButton;
