import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@mui/material';

function LogoutButton(key) {
  const { logout } = useAuth0();

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Button
        key={key}
        color="secondary"
        type="button"
        onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
        sx={{
          color: '#000', whiteSpace: 'nowrap', borderRadius: '25px', textTransform: 'none', marginX: '8px',
        }}
      >
        Log Out
      </Button>
    </div>
  );
}

export default LogoutButton;
