import * as React from 'react';
import {
  Box, Typography, Button, RadioGroup, FormControl, useTheme, useMediaQuery,
} from '@mui/material';
import Mail from '@mui/icons-material/Mail';
import PointOfSale from '@mui/icons-material/PointOfSale';
import { useAuth0 } from '@auth0/auth0-react';
import { colors } from './Theme';
import CustomFormControlLabel from './CustomFormControlLabel';
import Offers from './Offers';

function Pricing() {
  const { loginWithRedirect } = useAuth0();
  const [selectedValue, setSelectedValue] = React.useState('a');

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const fontSize = isSmallScreen ? '2rem' : '3.125rem';
  const fontSizeBig = isSmallScreen ? '3rem' : '6.125rem';

  return (
    <Box>
      <Box sx={{
        display: 'flex', backgroundColor: colors.lightGrey, flexDirection: 'column', alignItems: 'center',
      }}
      >
        <Typography
          variant="h1"
          sx={{
            marginBottom: '-30px', color: '#00000008', fontSizeBig, textAlign: 'center',
          }}
        >
          No monthly pricing
        </Typography>
        <Typography variant="h3" sx={{ fontSize }}>
          Pricing
        </Typography>
      </Box>
      <Box sx={{
        width: '100%', height: '100px', backgroundColor: colors.lightGrey, clipPath: 'polygon(100% 0, 0 0%, 100% 100%)',
      }}
      />
      <Offers />
      <Box sx={{
        width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden',
      }}
      >
        <Typography
          variant="h3"
          width="50%"
          sx={{
            fontWeight: '500', fontSize: '2rem', textAlign: 'center', marginTop: '40px', marginBottom: '40px',
          }}
        >
          Login to purchase credits. And no, no monthly plans.
        </Typography>
      </Box>
      <Box sx={{
        width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden', marginBottom: '40px',
      }}
      >
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="demo-form-control-label-placement"
            name="position"
            defaultValue="top"
          >
            <CustomFormControlLabel
              topLabel="$1"
              bottomLabel="100"
              value="1"
              selectedValue={selectedValue}
              onChange={handleChange}
            />
            <CustomFormControlLabel
              topLabel="$2"
              bottomLabel="200"
              value="2"
              selectedValue={selectedValue}
              onChange={handleChange}
            />
            <CustomFormControlLabel
              topLabel="$3"
              bottomLabel="300"
              value="3"
              selectedValue={selectedValue}
              onChange={handleChange}
            />
            <CustomFormControlLabel
              topLabel="$4"
              bottomLabel="400"
              value="4"
              selectedValue={selectedValue}
              onChange={handleChange}
            />
            <CustomFormControlLabel
              topLabel="$5"
              bottomLabel="525"
              value="5"
              selectedValue={selectedValue}
              onChange={handleChange}
            />
            <CustomFormControlLabel
              topLabel="$6"
              bottomLabel="625"
              value="6"
              selectedValue={selectedValue}
              onChange={handleChange}
            />
            <CustomFormControlLabel
              topLabel="$7"
              bottomLabel="725"
              value="7"
              selectedValue={selectedValue}
              onChange={handleChange}
            />
            <CustomFormControlLabel
              topLabel="$8"
              bottomLabel="825"
              value="8"
              selectedValue={selectedValue}
              onChange={handleChange}
            />
            <CustomFormControlLabel
              topLabel="$9"
              bottomLabel="925"
              value="9"
              selectedValue={selectedValue}
              onChange={handleChange}
            />
            <CustomFormControlLabel
              topLabel="$10"
              bottomLabel="1100"
              value="10"
              selectedValue={selectedValue}
              onChange={handleChange}
            />
          </RadioGroup>
        </FormControl>
      </Box>

      <Box sx={{
        width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden',
      }}
      >

        <Button
          variant="outlined"
          type="button"
          onClick={() => loginWithRedirect()}
          startIcon={<PointOfSale />}
          sx={{
            borderColor: colors.lightBlue,
            '&:hover': {
              borderColor: colors.lightBlue,
              borderWidth: '2px',
            },
            '&:active': {
              borderColor: colors.lightBlue,
              borderWidth: '2px',
            },
            '&.Mui-disabled': {
              borderColor: colors.lightBlue,
              borderWidth: '2px',
            },
            borderWidth: '2px',
            whiteSpace: 'nowrap',
            borderRadius: '25px',
            textTransform: 'none',
            paddingTop: '16px',
            paddingBottom: '16px',
            paddingLeft: '40px',
            paddingRight: '40px',
            marginBottom: '40px',
          }}
        >
          Login to Purchase credits!
        </Button>
      </Box>
      <Box sx={{
        width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden',
      }}
      >
        <Typography variant="h5" width="50%" sx={{ fontWeight: '500', textAlign: 'center', fontSize: '1rem' }}>
          With 100 credits you will be able to generate about 20 captions. Credits never expire.
          All prices are in USD.
        </Typography>
      </Box>
      <Box
        display="flex"
        marginTop="20px"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography>
          Need something else?
        </Typography>
        <Button
          variant="contained"
          type="button"
          onClick={() => { window.location.href = 'mailto:karl@solcraftcity.com'; }}
          endIcon={<Mail />}
          sx={{
            color: '#fff',
            whiteSpace: 'nowrap',
            borderRadius: '25px',
            textTransform: 'none',
            paddingTop: '16px',
            paddingBottom: '16px',
            paddingLeft: '40px',
            paddingRight: '40px',
            backgroundColor: '#000',
            marginTop: '20px',
          }}
        >
          Contact us!
        </Button>
      </Box>

    </Box>
  );
}

export default Pricing;
