import { createTheme } from '@mui/material';

export const colors = {
  darkBlue: '#196B80',
  lightBlue: '#35A8C4',
  lightGrey: '#E9EFF1',
  textColor: '#393939',
  textColorLight: '#B2B2B2',
};

export default () => createTheme({
  palette: {
    primary: {
      main: colors.lightBlue,
    },
    secondary: {
      main: colors.lightBlue,
    },
    background: {
      default: colors.lightGrey,
    },

  },
  typography: {
    fontFamily: 'Roboto',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontSize: '3.75rem',
      fontWeight: 900,
      color: colors.textColor,
    },
    h3: {
      fontSize: '3.125rem',
      fontWeight: 900,
      color: colors.textColor,
    },
    h4: {
      fontSize: '1.3rem',
      fontWeight: 500,
      color: colors.darkBlue,
    },
    h5: {
      fontSize: '1.563rem',
      fontWeight: 500,
      color: colors.textColor,
      fontStyle: 'italic',
    },
    h6: {
      fontSize: '1.25rem',
      fontWeight: 500,
      color: colors.textColorLight,
      fontStyle: 'italic',
    },
  },
});
