import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Tooltip, IconButton,
  Box, Button, Grid, Select, Backdrop, CircularProgress,
  MenuItem, Checkbox, TextField, Typography,
  Dialog, DialogActions, DialogTitle, DialogContent,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useNavigate } from 'react-router-dom';
import { colors } from './Theme';
import resizeImage from './Resize';

function Generate({ credits, freeGenerationsLeft, resetLoadedData }) {
  const {
    isAuthenticated, isLoading, getAccessTokenSilently,
  } = useAuth0();
  const [file, setFile] = React.useState(undefined);
  const [results, setResults] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [errorTitle, setErrorTitle] = React.useState('');
  const [errorText, setErrorText] = React.useState('');

  const [form, setForm] = React.useState({
    image: '',
    language: 'english',
    originalFileName: '',
    tonality: 'neutral',
    extraContext: '',
    freeTier: true && freeGenerationsLeft > 0,
    audience: 'instagram',
    includeHashtags: true,
    includeEmojis: false,
    amountOfSentences: 2,
  });

  const openDialog = (title, text) => {
    setErrorTitle(title);
    setErrorText(text);
    setOpen(true);
  };
  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
  };

  if (isLoading) {
    return <div />;
  }

  const handleImagePicked = async (e) => {
    if (e.target.files[0] === undefined) {
      return;
    }
    const image = e.target.files[0];
    const resizedImage = await resizeImage(image);
    setFile(resizedImage);
    setForm({
      ...form,
      originalFileName: image.name,
      image: resizedImage,
    });
  };

  const generate = (e) => {
    e.preventDefault();
    if (form.image === undefined || form.image === '') {
      openDialog('Oops!', 'Please upload a photo first.');
      return;
    }
    if (credits <= 0) {
      openDialog('Oops!', 'You don\'t have enough credits. Get Top up some credits first!');
      return;
    }
    if (form.freeTier && freeGenerationsLeft <= 0) {
      openDialog('Oops!', 'You have no free generations left. Either wait for tomorrow for free or purchase to continue!');
      return;
    }

    setLoading(true);
    getAccessTokenSilently().then((token) => {
      fetch(
        `${process.env.REACT_APP_BACKEND_HOST}generate`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(form),
        },
      )
        .then((response) => response.json())
        .then((data) => {
          setResults({ caption: data, image: form.image });
        })
        .finally(() => setLoading(false));
    })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleChange = (e) => {
    if (e.target.name === 'amountOfSentences') {
      setForm({
        ...form,
        [e.target.name]: parseInt(e.target.value, 10),
      });
      return;
    }
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleCheckboxChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.checked,
    });
  };

  const handleFreeCheckboxChange = (e) => {
    if (e.target.checked && freeGenerationsLeft <= 0) {
      openDialog('Oops!', 'You have no free generations left. Either wait for tomorrow for free or purchase to continue!');
    }
    setForm({
      ...form,
      [e.target.name]: e.target.checked && freeGenerationsLeft > 0,
    });
  };

  if (Object.keys(results).length > 0) {
    resetLoadedData();
    navigate('/generate-success', {
      state: {
        image: results.image,
        caption: results.caption,
      },
    });
  }

  return (
    isAuthenticated && (
    <Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading || isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{
          color: 'black',
          fontStyle: 'normal',
        }}
        >
          {errorTitle}
        </DialogTitle>
        <DialogContent>
          {errorText}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Box sx={{ width: '100%' }}>
        <Box sx={{
          width: '100%', height: '100px', backgroundColor: colors.lightGrey, clipPath: 'polygon(100% 0, 0 0%, 100% 100%)',
        }}
        />
        <Grid container spacing={2} sx={{ padding: '40px' }}>
          <Grid item xs={12} sm={6}>
            {file !== undefined ? (
              <Box sx={{
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
              }}
              >
                <img height="350" alt="Preview" src={file} />
              </Box>
            ) : <div />}
            {file === undefined ? (
              <Box sx={{
                width: '100%',
                height: 300,
                border: '1px dashed grey',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              >
                Click the button below to upload your photo.
              </Box>
            ) : <div />}
            <Box display="flex" flexDirection="column" alignItems="center" gap={2} marginTop="20px">
              <Button
                variant="contained"
                component="label"
                color="primary"
                sx={{
                  color: file === undefined ? '#fff' : '#000',
                  mr: 4,
                  textTransform: 'none',
                  borderRadius: '25px',
                  width: '200px',
                  height: '50px',
                  border: `2px solid ${colors.lightBlue}`,
                  backgroundColor: file === undefined ? colors.lightBlue : '#fff',
                  '&:hover': {
                    backgroundColor: file === undefined ? colors.darkBlue : '#fff',
                  },
                }}
              >
                Upload Photo
                <input type="file" onChange={handleImagePicked} accept="image/*" hidden />
              </Button>
              {file !== undefined && (
              <Button
                variant="contained"
                onClick={generate}
                component="label"
                color="primary"
                sx={{
                  color: '#fff', mr: 4, textTransform: 'none', borderRadius: '25px', width: '200px', height: '50px',
                }}
              >
                Generate my caption!
              </Button>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={2} alignItems="center" justifyContent="center">
              <Grid item xs={4}>
                <Typography align="right" sx={{ fontWeight: '600' }}>Generate for Free!</Typography>
              </Grid>
              <Grid item xs={8}>
                <Checkbox
                  checked={form.freeTier && freeGenerationsLeft > 0}
                  onChange={handleFreeCheckboxChange}
                  name="freeTier"
                />
                {' '}
                <Tooltip title="With free generation you can only generate with settings below, 3 times a day.">
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={4}>
                <Typography align="right" sx={{ fontWeight: '600' }}>Language</Typography>
              </Grid>
              <Grid item xs={8}>
                <Select
                  value={form.language}
                  onChange={handleChange}
                  fullWidth
                  name="language"
                  disabled={form.freeTier}
                >
                  <MenuItem value="english">English</MenuItem>
                  <MenuItem value="dutch">Dutch</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={4}>
                <Typography align="right" sx={{ fontWeight: '600' }}>Tonality</Typography>
              </Grid>
              <Grid item xs={8}>
                <Select
                  value={form.tonality}
                  onChange={handleChange}
                  fullWidth
                  name="tonality"
                  disabled={form.freeTier}
                >
                  <MenuItem value="neutral">Neutral</MenuItem>
                  <MenuItem value="positive">Positive</MenuItem>
                  <MenuItem value="negative">Negative</MenuItem>
                  <MenuItem value="serious">Serious</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={4}>
                <Typography align="right" sx={{ fontWeight: '600' }}>Audience</Typography>
              </Grid>
              <Grid item xs={8}>
                <Select
                  value={form.audience}
                  onChange={handleChange}
                  fullWidth
                  name="audience"
                  disabled={form.freeTier}
                >
                  <MenuItem value="instagram">Instagram</MenuItem>
                  <MenuItem value="linkedin">Linkedin</MenuItem>
                  <MenuItem value="facebook">Facebook</MenuItem>
                  <MenuItem value="x">X</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={4}>
                <Typography align="right" sx={{ fontWeight: '600' }}>Amount of Sentences</Typography>
              </Grid>
              <Grid item xs={8}>
                <Select
                  value={form.amountOfSentences}
                  onChange={handleChange}
                  fullWidth
                  name="amountOfSentences"
                  disabled={form.freeTier}
                >
                  <MenuItem value="1">1</MenuItem>
                  <MenuItem value="2">2</MenuItem>
                  <MenuItem value="3">3</MenuItem>
                  <MenuItem value="4">4</MenuItem>
                  <MenuItem value="5">5</MenuItem>
                  <MenuItem value="6">6</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={4}>
                <Typography align="right" sx={{ fontWeight: '600' }}>Hashtags</Typography>
              </Grid>
              <Grid item xs={8}>
                <Checkbox
                  checked={form.includeHashtags}
                  onChange={handleCheckboxChange}
                  name="includeHashtags"
                  disabled={form.freeTier}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography align="right" sx={{ fontWeight: '600' }}>Include Emoji</Typography>
              </Grid>
              <Grid item xs={8}>
                <Checkbox
                  checked={form.includeEmojis}
                  onChange={handleCheckboxChange}
                  name="includeEmojis"
                  disabled={form.freeTier}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography
                  align="right"
                  sx={{ fontWeight: '600' }}
                  disabled={form.freeTier}
                >
                  Extra Context
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  multiline
                  rows={4}
                  onChange={handleChange}
                  fullWidth
                  name="extraContext"
                  disabled={form.freeTier}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
    )
  );
}

export default Generate;
