export function formatShopifyAmount(amount) {
  return `$${(amount / 100).toFixed(2)}`;
}

export function formatDate(date) {
  return `${date.toLocaleTimeString()} ${date.toLocaleDateString()}`;
}

export function formatCreditHistoryItemStatus(status) {
  if (status === 'SPEND') {
    return 'Spend';
  }
  if (status === 'TOPUP') {
    return 'Top Up';
  }
  if (status === 'PROMOTION') {
    return 'Promotion 🎉';
  }
  return 'Unknown';
}

export function formatTonality(tonality) {
  if (tonality === 'neutral') {
    return 'Neutral';
  }
  if (tonality === 'positive') {
    return 'Positive';
  }
  if (tonality === 'negative') {
    return 'Negative';
  }
  if (tonality === 'serious') {
    return 'Serious';
  }
  return 'Unknown';
}

export function formatLanguage(language) {
  if (language === 'english') {
    return 'English';
  }
  if (language === 'dutch') {
    return 'Dutch';
  }
  return 'Unknown';
}

export function formatAudience(audience) {
  if (audience === 'instagram') {
    return 'Instagram';
  }
  if (audience === 'linkedin') {
    return 'LinkedIN';
  }

  if (audience === 'facebook') {
    return 'Facebook';
  }

  if (audience === 'x') {
    return 'X';
  }
  return 'Unknown';
}

export function formatBoolToEmoji(bool) {
  return bool ? '✅' : '❌';
}
