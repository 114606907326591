import React from 'react';
import {
  FormControl, FormLabel, Radio,
} from '@mui/material';

function CustomFormControlLabel({
  topLabel, bottomLabel, value, selectedValue, onChange,
}) {
  return (
    <FormControl component="fieldset">
      <FormLabel
        sx={{ textAlign: 'center' }}
        component="legend"
      >
        {topLabel}
      </FormLabel>
      <Radio
        checked={selectedValue === value}
        value={value}
        onChange={onChange}
      />
      <FormLabel
        sx={{ textAlign: 'center', fontWeight: 'bold' }}
        component="legend"
      >
        {bottomLabel}
      </FormLabel>
    </FormControl>
  );
}

export default CustomFormControlLabel;
