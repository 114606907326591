// AppBarComponent.jsx
import {
  AppBar, Toolbar, Typography, Box, Button,
  IconButton,
  Menu,
  Container,
  MenuItem,
} from '@mui/material';
import * as React from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import MenuOutlined from '@mui/icons-material/MenuOutlined';
import { TypeAnimation } from 'react-type-animation';
import ReactGA from 'react-ga4';
import LoginButton from './LoginButton';
import LogoutButton from './LogoutButton';

const pagesLoggedOut = [
  { title: 'Pricing', url: '/pricing', type: 'GENERIC' },
  { title: 'Login', url: '/login', type: 'LOGIN' },

];
const pagesLoggedIn = [
  { title: 'Generate', url: '/generate', type: 'GENERATE' },
  { title: 'Transactions', url: '/transactions', type: 'GENERIC' },
  { title: 'Purchase', url: '/purchase', type: 'GENERIC' },
  { title: 'Logout', url: '/logout', type: 'LOGOUT' },
];

function AppBarComponent({
  user, credits, isAuthenticated, promotions, freeGenerationsLeft,
}) {
  const location = useLocation();
  React.useEffect(() => {
    ReactGA.event('page_view', { page_location: location.pathname, is_authenticated: isAuthenticated });
  }, [location, credits, isAuthenticated, freeGenerationsLeft]);

  const navigate = useNavigate();

  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const textSequence = [];
  if (isAuthenticated) {
    textSequence.push(`Welcome back ${user.given_name}! Let's generate some captions!`);
  } else {
    textSequence.push('Welcome to Capgen City! The best place to generate captions!');
  }
  if (promotions !== undefined) {
    textSequence.push(300);
    textSequence.push(...promotions.map((promotion) => promotion.description));
  }
  const buttonBasedOnButtonType = (button) => {
    if (button.type === 'GENERIC') {
      return (
        <Button
          key={button.title}
          onClick={(() => {
            navigate(button.url);
          })}
          sx={{
            marginX: '8px',
            my: 2,
            color: 'black',
            display: 'block',
            textTransform: 'none',
          }}
        >
          {button.title}
        </Button>
      );
    }
    if (button.type === 'LOGIN') {
      return (
        <LoginButton key={button.title} />
      );
    }
    if (button.type === 'LOGOUT') {
      return (
        <LogoutButton key={button.title} />
      );
    }
    if (button.type === 'GENERATE') {
      return (
        <div key="generate" style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            key="generate_button"
            onClick={() => navigate('/generate')}
            color="primary"
            variant="contained"
            sx={{
              color: '#fff',
              mr: 4,
              marginX: '8px',
              textTransform: 'none',
              borderRadius: '25px 25px 25px 25px',
            }}
          >
            Generate!
          </Button>
        </div>
      );
    }
    return (<div>UNMAPPED BUTTON</div>);
  };

  let pages = pagesLoggedOut;
  if (isAuthenticated) {
    pages = pagesLoggedIn;
  }

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: '#E9EFF1',
        paddingTop: '40px',
        paddingBottom: '25px',
        paddingLeft: '5%',
        paddingRight: '5%',
      }}
    >
      <Container maxWidth="100%">
        <Toolbar disableGutters>
          <Typography
            variant="h3"
            noWrap
            component={Link}
            to="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              textDecoration: 'none',
              fontSize: '2.1875rem',
            }}
          >
            Capgen City
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuOutlined />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem
                  key={page.title}
                  onClick={
                  () => {
                    handleCloseNavMenu();
                    navigate(page.url);
                  }
                  }
                >
                  <Typography textAlign="center" sx={{ color: 'black' }}>{page.title}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Typography
            variant="h3"
            noWrap
            component={Link}
            to="/"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              color: 'inherit',
              fontSize: '2.1875rem',
              textDecoration: 'none',
            }}
          >
            Capgen City
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              display: {
                xs: 'none',
                md: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
              },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              {pages.map((page) => (
                buttonBasedOnButtonType(page)
              ))}
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              {
                credits >= 0
                && (
                  <Button
                    variant="contained"
                    sx={{
                      color: '#000',
                      backgroundColor: '#FFF',
                      mr: 4,
                      marginX: '8px',
                      textTransform: 'none',
                      borderRadius: '25px 25px 25px 25px',
                      '&:hover': {
                        backgroundColor: '#FFF',
                      },
                    }}
                  >
                    Credits:
                    {' '}
                    {credits}
                    {credits === 0 ? ' 😢' : ' 🤩'}
                  </Button>
                )
              }
              {
                freeGenerationsLeft >= 0
                && (
                  <Button
                    variant="contained"
                    sx={{
                      color: '#000',
                      backgroundColor: '#FFF',
                      mr: 4,
                      marginX: '8px',
                      textTransform: 'none',
                      borderRadius: '25px 25px 25px 25px',
                      '&:hover': {
                        backgroundColor: '#FFF',
                      },
                    }}
                  >
                    Free Generations:
                    {' '}
                    {freeGenerationsLeft}
                    {freeGenerationsLeft === 0 ? ' 😢' : ' ✨'}
                  </Button>
                )
              }
            </Box>
          </Box>
        </Toolbar>
        {promotions !== undefined && (
          <TypeAnimation
            sequence={textSequence}
            wrapper="span"
            speed={70}
            deletionSpeed={70}
            style={{ fontSize: '1.5rem', alignContent: 'center', textAlign: 'center' }}
          />
        )}
      </Container>
    </AppBar>
  );
}

export default AppBarComponent;
