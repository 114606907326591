import * as React from 'react';
import {
  Box, Typography, Button, RadioGroup, FormControl, Backdrop,
  Dialog, DialogTitle, DialogActions, DialogContent, CircularProgress,
  useTheme, useMediaQuery,
} from '@mui/material';
import Mail from '@mui/icons-material/Mail';

import Person from '@mui/icons-material/Person';
import { useAuth0 } from '@auth0/auth0-react';
import { colors } from './Theme';
import CustomFormControlLabel from './CustomFormControlLabel';
import Offers from './Offers';

function Purchase() {
  const { getAccessTokenSilently } = useAuth0();
  const [selectedValue, setSelectedValue] = React.useState('0');
  const [errorTitle, setErrorTitle] = React.useState('');
  const [errorText, setErrorText] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const openDialog = (title, text) => {
    setErrorTitle(title);
    setErrorText(text);
    setOpen(true);
  };

  const isSmallScreen = useMediaQuery(useTheme().breakpoints.down('sm'));
  const fontSize = isSmallScreen ? '2rem' : '3.125rem';
  const fontSizeBig = isSmallScreen ? '3rem' : '6.125rem';

  const purchase = () => {
    const credits = parseInt(selectedValue, 10);
    if (credits === 0) {
      openDialog('Oops!', 'Please select the amount of credits you want to purchase below.');
      return;
    }
    setIsLoading(true);
    getAccessTokenSilently().then((token) => {
      fetch(
        `${process.env.REACT_APP_BACKEND_HOST}stripe/initialise`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            credits,
          }),
        },
      )
        .then(async (response) => {
          if (await response.status !== 201) {
            openDialog('Error!', 'Something went wrong, please try again later.');
            return;
          }
          // eslint-disable-next-line consistent-return
          return response.json();
        })
        .then((data) => {
          window.location.href = data.url;
        })
        .finally(() => {
          setIsLoading(false);
        });
    }).catch(() => {
      setIsLoading(false);
    });
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{
          color: 'black',
          fontStyle: 'normal',
        }}
        >
          {errorTitle}
        </DialogTitle>
        <DialogContent>
          {errorText}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Box sx={{
        display: 'flex', backgroundColor: colors.lightGrey, flexDirection: 'column', alignItems: 'center',
      }}
      >
        <Typography
          variant="h1"
          sx={{
            marginBottom: '-30px', color: '#00000008', fontSizeBig, textAlign: 'center',
          }}
        >
          No monthly pricing
        </Typography>
        <Typography variant="h3" sx={{ fontSize }}>
          Purchase
        </Typography>
      </Box>
      <Box sx={{
        width: '100%', height: '100px', backgroundColor: colors.lightGrey, clipPath: 'polygon(100% 0, 0 0%, 100% 100%)',
      }}
      />
      <Offers />
      <Box sx={{
        width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden',
      }}
      >
        <Typography
          variant="h3"
          width="50%"
          sx={{
            fontWeight: '500', fontSize: '2rem', textAlign: 'center', marginTop: '40px', marginBottom: '40px',
          }}
        >
          Purchase credits here. And no, no monthly plans.
        </Typography>
      </Box>
      <Box sx={{
        width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden', marginBottom: '40px',
      }}
      >
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="demo-form-control-label-placement"
            name="position"
            defaultValue="top"
          >
            <CustomFormControlLabel
              topLabel="$1"
              bottomLabel="100"
              value="100"
              selectedValue={selectedValue}
              onChange={handleChange}
            />
            <CustomFormControlLabel
              topLabel="$2"
              bottomLabel="200"
              value="200"
              selectedValue={selectedValue}
              onChange={handleChange}
            />
            <CustomFormControlLabel
              topLabel="$3"
              bottomLabel="300"
              value="300"
              selectedValue={selectedValue}
              onChange={handleChange}
            />
            <CustomFormControlLabel
              topLabel="$4"
              bottomLabel="400"
              value="400"
              selectedValue={selectedValue}
              onChange={handleChange}
            />
            <CustomFormControlLabel
              topLabel="$5"
              bottomLabel="525"
              value="525"
              selectedValue={selectedValue}
              onChange={handleChange}
            />
            <CustomFormControlLabel
              topLabel="$6"
              bottomLabel="625"
              value="625"
              selectedValue={selectedValue}
              onChange={handleChange}
            />
            <CustomFormControlLabel
              topLabel="$7"
              bottomLabel="725"
              value="725"
              selectedValue={selectedValue}
              onChange={handleChange}
            />
            <CustomFormControlLabel
              topLabel="$8"
              bottomLabel="825"
              value="825"
              selectedValue={selectedValue}
              onChange={handleChange}
            />
            <CustomFormControlLabel
              topLabel="$9"
              bottomLabel="925"
              value="925"
              selectedValue={selectedValue}
              onChange={handleChange}
            />
            <CustomFormControlLabel
              topLabel="$10"
              bottomLabel="1100"
              value="1100"
              selectedValue={selectedValue}
              onChange={handleChange}
            />
          </RadioGroup>
        </FormControl>
      </Box>

      <Box sx={{
        width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden',
      }}
      >

        <Button
          variant="contained"
          type="button"
          onClick={() => purchase()}
          startIcon={<Person />}
          sx={{
            color: '#fff',
            borderWidth: '2px',
            whiteSpace: 'nowrap',
            borderRadius: '25px',
            textTransform: 'none',
            paddingTop: '16px',
            paddingBottom: '16px',
            paddingLeft: '40px',
            paddingRight: '40px',
            marginBottom: '40px',
          }}
        >
          Purchase!
        </Button>
      </Box>
      <Box sx={{
        width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden',
      }}
      >
        <Typography variant="h5" width="50%" sx={{ fontWeight: '500', textAlign: 'center', fontSize: '1rem' }}>
          With 100 credits you will be able to generate about 20 captions. Credits never expire.
          All prices are in USD.
        </Typography>
      </Box>
      <Box
        flexDirection="column"
        sx={{
          width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden',
        }}
      >
        {' '}
      </Box>
      <Box
        marginTop="20px"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography>
          Need something else?
        </Typography>
        <Button
          variant="contained"
          type="button"
          onClick={() => { window.location.href = 'mailto:karl@solcraftcity.com'; }}
          endIcon={<Mail />}
          sx={{
            color: '#fff',
            whiteSpace: 'nowrap',
            borderRadius: '25px',
            textTransform: 'none',
            paddingTop: '16px',
            paddingBottom: '16px',
            paddingLeft: '40px',
            paddingRight: '40px',
            backgroundColor: '#000',
            marginTop: '20px',
          }}
        >
          Contact us!
        </Button>
      </Box>

    </Box>
  );
}

export default Purchase;
