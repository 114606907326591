import * as React from 'react';
import {
  BrowserRouter as Router, Routes, Route,
} from 'react-router-dom';
import {
  ThemeProvider, Box, Dialog, DialogActions, DialogTitle, DialogContent,
  Button,
} from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import ReactGA from 'react-ga4';
import Theme from './Theme';
import Main from './Main';
import AppBarComponent from './AppBarComponent';
import Transactions from './Transactions';
import FooterComponent from './FooterComponent';
import Generate from './Generate';
import GenerateSuccess from './Generate_success';
import Pricing from './Pricing';
import Purchase from './Purchase';
import Success from './Success';
import Cancelled from './Cancelled';
import Support from './Support';
import Error from './Error';
import Login from './Login';
import Logout from './Logout';

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_NO);
const host = process.env.REACT_APP_BACKEND_HOST;

function App() {
  const {
    isAuthenticated, isLoading, getAccessTokenSilently, user,
  } = useAuth0();

  const [data, setData] = React.useState({
    credits: -1,
    freeGenerationsLeft: -1,
  });
  const [loadedData, setLoadedData] = React.useState(false);
  const [errorTitle, setErrorTitle] = React.useState('');
  const [errorText, setErrorText] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const openDialog = (title, text) => {
    setErrorTitle(title);
    setErrorText(text);
    setOpen(true);
  };

  if (isLoading) {
    return <div />;
  }
  if (!loadedData && isAuthenticated) {
    setLoadedData(true);
    const fetchData = async () => {
      const token = await getAccessTokenSilently();
      Promise.all([
        fetch(`${host}promotions`),
        fetch(`${host}me`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }),
      ]).then(async ([promotionsRsp, meRsp]) => {
        const meData = await meRsp.json();
        setData({
          credits: meData.totalCredits,
          freeGenerationsLeft: meData.freeGenerationsLeft,
          promotions: await promotionsRsp.json(),
        });
      });
    };
    fetchData().then().catch(() => {
      openDialog('Error!', 'Something went wrong, please try again later.');
    });
  }

  if (!loadedData && !isAuthenticated) {
    setLoadedData(true);
    const fetchData = async () => {
      const promotionsRsp = await fetch(`${host}promotions`);
      setData({
        credits: -1,
        freeGenerationsLeft: -1,
        promotions: await promotionsRsp.json(),
      });
    };
    fetchData().then().catch(() => {
      openDialog('Error!', 'Something went wrong, please try again later.');
    });
  }
  const resetLoadedData = () => {
    setLoadedData(false);
  };
  return (
    <ThemeProvider theme={Theme()}>
      <Router>
        <Box className="App" sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle sx={{
              color: 'black',
              fontStyle: 'normal',
            }}
            >
              {errorTitle}
            </DialogTitle>
            <DialogContent>
              {errorText}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
          <AppBarComponent
            user={user}
            credits={data.credits}
            isAuthenticated={isAuthenticated}
            promotions={data.promotions}
            freeGenerationsLeft={data.freeGenerationsLeft}
          />
          <Box sx={{ flex: '1 0 auto' }}>
            <Routes>
              <Route path="/" element={<Main />} />
              {isAuthenticated ? (
                <>
                  <Route path="/generate" element={<Generate credits={data.credits} freeGenerationsLeft={data.freeGenerationsLeft} resetLoadedData={resetLoadedData} />} />
                  <Route path="/generate-success" element={<GenerateSuccess />} />
                  <Route path="/transactions" element={<Transactions />} />
                  <Route path="/success" element={<Success />} />
                  <Route path="/purchase" element={<Purchase />} />
                  <Route path="/cancelled" element={<Cancelled />} />
                  <Route path="/logout" element={<Logout />} />
                </>
              ) : (
                null
              )}
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/support" element={<Support />} />
              <Route path="/error" element={<Error />} />
              <Route path="/login" element={<Login />} />
            </Routes>
          </Box>
          <Box sx={{ flexShrink: 0 }}>
            <FooterComponent />
          </Box>
        </Box>
      </Router>
    </ThemeProvider>
  );
}

export default App;
