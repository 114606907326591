import React from 'react';
import {
  Typography, Box, Button,
} from '@mui/material';
import { colors } from './Theme';

function Success() {
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      paddingBottom: '40px',
    }}
    >
      <Box sx={{
        width: '100%', height: '100px', backgroundColor: colors.lightGrey, clipPath: 'polygon(100% 0, 0 0%, 100% 100%)',
      }}
      />
      <Typography
        variant="h1"
        sx={{
          paddingTop: '40px',
          paddingBottom: '40px',
          color: colors.lightBlue,
          fontStyle: 'italic',
        }}
      >
        Credits Purchased Successully!
      </Typography>
      <Typography variant="h5" width="50%" sx={{ fontWeight: '500', textAlign: 'center', fontSize: '1rem' }}>
        Let&apos;s start generating some captions!
      </Typography>
      <Button
        variant="contained"
        color="secondary"
        type="button"
        onClick={() => { window.location.href = '/generate'; }}
        sx={{
          color: '#fff', whiteSpace: 'nowrap', borderRadius: '25px', textTransform: 'none', padding: '16px', marginTop: '40px',
        }}
      >
        Let&apos;s go!
      </Button>
    </Box>

  );
}

export default Success;
