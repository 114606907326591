import React from 'react';
import { Typography, Box } from '@mui/material';

function Error() {
  return (
    <Box style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      padding: '40px',
    }}
    >
      <Typography variant="h2" sx={{ paddingBottom: '20px' }}>
        Oops! Something went wrong.
      </Typography>
      <Typography variant="h6">
        Please try again later.
      </Typography>
    </Box>
  );
}

export default Error;
