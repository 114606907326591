import React, { useState } from 'react';
import {
  Box, TextField, Button, Typography, Dialog, DialogTitle,
  DialogContent, DialogContentText, DialogActions,
} from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';

function Support() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [text, setText] = useState('');
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [textError, setTextError] = useState(false);
  const [requestSuccess, setRequestSuccess] = useState(false);
  const [open, setOpen] = React.useState(false);
  const {
    isAuthenticated, isLoading, user,
  } = useAuth0();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const validateEmail = (e) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(e);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
    setNameError(e.target.value === '');
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailError(!validateEmail(e.target.value));
  };

  const handleTextChange = (e) => {
    setText(e.target.value);
    setTextError(e.target.value.length > 200);
  };

  const handleSubmit = () => {
    if (name === '' || email === '' || text === '') {
      setNameError(name === '');
      setEmailError(email === '');
      setTextError(text === '');
      return;
    }

    if (!validateEmail(email)) {
      setEmailError(true);
      return;
    }

    fetch(`${process.env.REACT_APP_BACKEND_HOST}support`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name, email, text }),
    })
      .then((response) => {
        if (response.status !== 201) {
          throw new Error('Error');
        }
        setName('');
        setEmail('');
        setText('');
        setNameError(false);
        setEmailError(false);
        setTextError(false);
        setRequestSuccess(true);
        handleClickOpen();
      })
      .catch(() => {
        setRequestSuccess(false);
        handleClickOpen();
      });
  };

  if (!isLoading && isAuthenticated && email === '' && name === '') {
    setEmail(user.email);
    setName(user.name);
  }
  return (
    <Box sx={{
      padding: '40px', display: 'flex', flexDirection: 'column',
    }}
    >
      <Typography variant="h3" sx={{ marginBottom: '20px' }}>
        Support
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: '20px' }}>
        Do you need support? Or do you simply have a question?
        Just fill out the form and make sure you are reachable on that email.
        We will try to get back to you as soon as possible!
      </Typography>

      <TextField
        required
        label="Name"
        value={name}
        disabled={!isLoading && isAuthenticated}
        onChange={handleNameChange}
        error={nameError}
        helperText={nameError ? 'Name is required' : ''}
        sx={{ marginBottom: '20px', width: '50%', minWidth: '300px' }}
      />
      <TextField
        required
        label="Email"
        value={email}
        type="email"
        disabled={!isLoading && isAuthenticated}
        onChange={handleEmailChange}
        error={emailError}
        helperText={emailError ? 'Invalid email' : ''}
        sx={{ marginBottom: '20px', width: '50%', minWidth: '300px' }}
      />
      <TextField
        required
        label="Enquiry"
        value={text}
        onChange={handleTextChange}
        error={textError}
        helperText={textError ? 'Text must be less than 200 characters' : ''}
        multiline
        rows={4}
        sx={{ marginBottom: '20px', width: '50%', minWidth: '300px' }}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        sx={{ color: '#FFF', width: '50%', minWidth: '300px' }}
      >
        Submit
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ color: '#000' }}>
          { requestSuccess ? 'Success!' : 'Error!'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{ color: '#000', fontStyle: 'normal' }}>
            { requestSuccess ? 'Enquiry sent successfully! We will get back to you as soon as possible.' : 'Something went wrong! Please try again later.'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default Support;
