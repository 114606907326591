import * as React from 'react';
import {
  Grid, Box, Typography,
} from '@mui/material';
import { colors } from './Theme';

function Offer() {
  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    }}
    >
      <Typography
        variant="h3"
        width="50%"
        sx={{
          fontWeight: '500', fontSize: '2rem', textAlign: 'center', marginTop: '40px', marginBottom: '12px',
        }}
      >
        We offer 2 tiers types of generation. Choose the one that fits you best.
      </Typography>
      <Box sx={{
        display: 'flex', justifyContent: 'space-between', marginTop: '20px', paddingLeft: '20px', paddingRight: '20px',
      }}
      >
        <Grid container spacing={20} justifyContent="center" alignItems="top">
          <Grid item sm={6}>
            <Box
              flexDirection="column"
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                backgroundColor: colors.lightGrey,
                padding: '20px',
                borderRadius: '10px',
                width: '350px',
                height: '275px',
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  fontWeight: '700', fontSize: '1.5rem', textAlign: 'center',
                }}
              >
                Free generation (forever)
              </Typography>
              <Typography
                variant="h3"
                sx={{
                  fontWeight: '300',
                  fontSize: '1.2rem',
                  textAlign: 'center',
                  marginTop: '12px',
                }}
              >
                3 generations/daily
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontSize: '1rem',
                  color: '#000',
                  fontStyle: 'normal',
                }}
              >
                <ul style={{ listStyle: 'none' }}>
                  <li>✔️ Support one language: English</li>
                  <li>✔️ Support one tonality: Neutral</li>
                  <li>✔️ Support one target audience: Instagram</li>
                  <li>✔️ Includes hashtags</li>
                  <li />
                </ul>
              </Typography>
            </Box>
          </Grid>
          <Grid item sm={6}>
            <Box
              flexDirection="column"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                backgroundColor: colors.lightGrey,
                padding: '20px',
                borderRadius: '10px',
                width: '350px',
                height: '275px',
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  fontWeight: '700', fontSize: '1.5rem', textAlign: 'center',
                }}
              >
                Paid generation
              </Typography>
              <Typography
                variant="h3"
                sx={{
                  fontWeight: '300',
                  fontSize: '1.2rem',
                  textAlign: 'center',
                  marginTop: '12px',
                }}
              >
                5~ credits/generation
              </Typography>
              {' '}
              <Typography
                variant="h6"
                sx={{
                  fontSize: '1rem',
                  color: '#000',
                  fontStyle: 'normal',
                }}
              >
                <ul style={{ listStyle: 'none' }}>
                  <li>✔️ Everything in Free Generation</li>
                  <li>✔️ Choice of multiple languages</li>
                  <li>✔️ Choice of multiple tonality</li>
                  <li>✔️ Choice of multiple adience</li>
                  <li>✔️ Choice to include emojis</li>
                  <li>✔️ Choice to include hashtags</li>
                  <li>✔️ Ability to provide extra context in text.</li>
                </ul>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default Offer;
