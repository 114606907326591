/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography,
  Box,
} from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { colors } from './Theme';
import {
  formatLanguage, formatTonality, formatAudience,
  formatCreditHistoryItemStatus, formatShopifyAmount, formatDate, formatBoolToEmoji,
} from './Format';

function Transactions() {
  const [transactions, setTransactions] = useState();
  const [loadedApi, setLoadedApi] = useState(false);
  const {
    isAuthenticated, isLoading, getAccessTokenSilently,
  } = useAuth0();
  if (isLoading) {
    return <Box>Loading ...</Box>;
  }

  if (isAuthenticated && !loadedApi) {
    setLoadedApi(true);
    getAccessTokenSilently().then((token) => {
      fetch(
        `${process.env.REACT_APP_BACKEND_HOST}history`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      )
        .then((response) => response.json())
        .then((data) => setTransactions(data));
    });
  }
  return (isAuthenticated && (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      paddingTop: '40px',
      paddingBottom: '40px',
    }}
    >
      <Typography variant="h1" sx={{ paddingBottom: '20px', fontSize: '2.0rem' }}>
        Captions History
      </Typography>
      <Box sx={{
        display: 'flex', justifyContent: 'center', alignItems: 'center', width: '80%',
      }}
      >
        <TableContainer component={Paper} sx={{ marginBottom: '40px' }}>
          <Table>
            <TableHead sx={{ bgcolor: colors.lightBlue, borderRadius: '16px', color: 'white' }}>
              <TableRow>
                <TableCell sx={{ color: 'white' }}>Date</TableCell>
                <TableCell sx={{ color: 'white' }}>Language</TableCell>
                <TableCell sx={{ color: 'white' }}>Audience</TableCell>
                <TableCell sx={{ color: 'white' }}>Tonality</TableCell>
                <TableCell sx={{ color: 'white' }}>Free Tier</TableCell>
                <TableCell sx={{ color: 'white' }}>Hashtags</TableCell>
                <TableCell sx={{ color: 'white' }}>Emojis</TableCell>
                <TableCell sx={{ color: 'white' }}>Extra Content</TableCell>
                <TableCell sx={{ color: 'white' }}>Filename</TableCell>
                <TableCell sx={{ color: 'white' }}>Results</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactions === undefined
                ? <TableRow />
                : transactions.captionHistoryItems.map((transaction) => {
                  const date = new Date(transaction.createdAt); return (
                    <TableRow key={date.getMilliseconds()}>
                      <TableCell>
                        {`${formatDate(date)}`}
                      </TableCell>
                      <TableCell>{formatLanguage(transaction.language)}</TableCell>
                      <TableCell>{formatAudience(transaction.audience)}</TableCell>
                      <TableCell>{formatTonality(transaction.tonality)}</TableCell>
                      <TableCell>{formatBoolToEmoji(transaction.freeTier)}</TableCell>
                      <TableCell>{formatBoolToEmoji(transaction.includeHashtags)}</TableCell>
                      <TableCell>{formatBoolToEmoji(transaction.includeEmojis)}</TableCell>
                      <TableCell>{transaction.extraContent}</TableCell>
                      <TableCell>{transaction.fileName}</TableCell>
                      <TableCell>{transaction.result}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Typography variant="h1" sx={{ paddingBottom: '20px', fontSize: '2.0rem' }}>
        Transactions
      </Typography>
      <Box sx={{
        display: 'flex', justifyContent: 'center', alignItems: 'center', width: '40%',
      }}
      >
        <TableContainer component={Paper} sx={{ marginBottom: '40px' }}>
          <Table>
            <TableHead sx={{ bgcolor: colors.lightGrey, borderRadius: '16px' }}>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Credits</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactions === undefined
                ? <TableRow />
                : transactions.transactionHistoryItems.map((transaction) => {
                  const date = new Date(transaction.createdAt); return (
                    <TableRow key={date.getMilliseconds()}>
                      <TableCell>
                        {`${formatDate(date)}`}
                      </TableCell>
                      <TableCell>{formatShopifyAmount(transaction.amount)}</TableCell>
                      <TableCell>{transaction.creditsAmount}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Typography variant="h1" sx={{ paddingBottom: '20px', fontSize: '2.0rem' }}>
        Credits History
      </Typography>
      <Box sx={{
        display: 'flex', justifyContent: 'center', alignItems: 'center', width: '40%',
      }}
      >
        <TableContainer component={Paper} sx={{ marginBottom: '20px' }}>
          <Table>
            <TableHead sx={{ bgcolor: colors.lightGrey, borderRadius: '16px' }}>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Activity</TableCell>
                <TableCell>Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactions === undefined
                ? <TableRow />
                : transactions.creditHistoryItems.map((transaction) => {
                  const date = new Date(transaction.createdAt); return (
                    <TableRow key={date.getMilliseconds()}>
                      <TableCell>
                        {`${formatDate(date)}`}
                      </TableCell>
                      <TableCell>{ formatCreditHistoryItemStatus(transaction.activity)}</TableCell>
                      <TableCell>{transaction.credits}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  )
  );
}

export default Transactions;
